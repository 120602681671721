<template>
  <Layout class="VirtualKeyboardList">
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        inline
        :rules="rules"
      >
        <el-form-item
          label="用户ID"
          prop="userIdStr"
        >
          <el-input
            v-model="formData.userIdStr"
            placeholder="用户ID"
            clearable
            @keyup.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item
          label="游戏sourceId"
          prop="sourceId"
        >
          <el-input
            v-model="formData.sourceId"
            placeholder="sourceId"
            @keyup.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item
          label="方案ID"
          prop="keyId"
        >
          <el-input
            v-model="formData.keyId"
            placeholder="keyId"
            @keyup.enter="handleSearch"
          />
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formData.keyType">
            <el-option
              v-for="(item, index) in keyboardTypeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      @sort-change="handleSortTable"
    >
      <!-- <el-table-column
        prop="No"
        label="编号"
      /> -->
      <el-table-column
        prop="keyId"
        label="方案ID"
      />
      <el-table-column
        prop="keyName"
        label="方案名称"
        width="200"
      />
      <el-table-column
        prop="gameName"
        label="游戏名称"
      />
      <el-table-column
        prop="userIdStr"
        label="创建者ID"
      />
      <el-table-column
        label="创建时手机分辨率"
        width="150px"
      >
        <template #default="{row}">
          {{ row.resolutionWidth }} X {{ row.resolutionHeight }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="times"
        label="使用时长(秒）"
        sortable="custom"
        width="140px"
      >
        <template>
          /
        </template>
      </el-table-column> -->
      <el-table-column
        prop="usageCount"
        label="使用次数"
        sortable="custom"
        width="100px"
      />
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180px"
      />
      <el-table-column
        prop="updateTime"
        label="最后更新时间"
        width="180px"
      />
      <el-table-column
        prop="keyType"
        label="类型"
      >
        <template #default="{row}">
          {{ keyboardTypeMap[row.keyType] || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200px"
        fixed="right"
      >
        <template #default="{row}">
          <el-button
            type="text"
            @click="handlePreviewKeyboardPlan(row)"
          >
            预览
          </el-button>
          <el-button
            type="text"
            @click="handleEditKeyboardPlan(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
    <el-drawer
      :title="currentData.keyName"
      :visible.sync="previewKeyboardPlan"
      direction="btt"
      size="600px"
    >
      <VirtualKeyboard
        :data="currentData"
        style="margin: 0 auto;"
      />
    </el-drawer>

    <el-dialog
      title="编辑键盘"
      :visible.sync="editKeyboardPlan"
      width="500px"
    >
      <el-form
        ref="edit"
        :model="currentData"
      >
        <el-form-item
          label="方案名称"
          prop="keyName"
          :rules="[{required: true, message: '方案名称必填'}]"
        >
          <el-input v-model="currentData.keyName" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editKeyboardPlan = false">取 消</el-button>
        <el-button
          v-loading="updateLoading"
          type="primary"
          @click="handleConfirmEdit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>

<script>
import VirtualKeyboard from '@/components/VirtualKeyboard/index.vue'
import { cloneDeep } from 'lodash'
import {
  getKeyBoardList,
  updateKeyBoardName
} from '@/api/keyboard.js'

export default {
  name: 'VirtualKeyboardPage',
  components: { VirtualKeyboard },
  data () {
    const numberCheck = (name) => {
      return (rule, value, callback) => {
        const reg = /^[0-9]*$/
        if (value && !reg.test(value)) {
          callback(new Error(name + '必须是数字'))
        } else {
          callback()
        }
      }
    }
    return {
      previewKeyboardPlan: false,
      editKeyboardPlan: false,
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      keyboardTypeList: [
        { name: '虚拟键盘', value: 1 },
        { name: '虚拟手柄', value: 2 }
      ],
      keyboardTypeMap: {
        1: '虚拟键盘',
        2: '虚拟手柄'
      },
      listInfo: {
        list: [],
        loading: false,
        total: 0
      },
      currentData: {},
      updateLoading: false,
      rules: {
        userIdStr: [{ validator: numberCheck('用户ID') }],
        sourceId: [{ validator: numberCheck('SourceID') }],
        keyId: [{ validator: numberCheck('方案ID') }]
      }
    }
  },
  mounted () {
    this.handleSearch()
  },
  methods: {
    handleSearch () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.formData.pageNum = 1
          this.queryDataList()
        }
      })
    },
    queryDataList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.listInfo.loading = true
          getKeyBoardList(this.formData)
            .then(res => {
              if (res.code === 200) {
                this.listInfo.list = res.data.list || []
                this.listInfo.total = res.data.total
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        }
      })
    },
    handleSortTable ({ prop, order }) {
      this.formData.sortKey = prop
      const sortType = order === 'ascending' ? 'asc' : order === 'descending' ? 'desc' : ''
      this.formData.sortType = sortType
      if (sortType) { this.queryDataList() }
    },
    handlePreviewKeyboardPlan (row) {
      this.previewKeyboardPlan = true
      this.currentData = cloneDeep(row)
    },
    handleEditKeyboardPlan (row) {
      this.editKeyboardPlan = true
      this.currentData = cloneDeep(row)
    },
    handleConfirmEdit () {
      this.$refs.edit.validate(valid => {
        if (valid) {
          const { keyId, keyName } = this.currentData
          this.updateLoading = true
          updateKeyBoardName({ keyId, keyName })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('更新成功')
                this.editKeyboardPlan = false
                this.queryDataList()
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less">
  .VirtualKeyboardList {
    .el-drawer__header {
      text-align: center;
    }
    .el-drawer .el-drawer__body {
      padding: 0;
    }
  }
</style>
